import React from 'react';
import styled from "styled-components";
import Header from "../components/header";
import Footer from "../components/footer";
import YandexMap from "../components/yandexMap"
import Decoration from "../images/decoration.svg"
import Certificates from "../images/certificates.pdf"
import {graphql, useStaticQuery} from "gatsby";
import "../index.css"
import Seo from "../components/seo";


const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  width: 1110px;
  align-self: center;
  position: relative;
  @media screen and (max-width: 1100px) {
    width: 1000px;
  }
  @media screen and (max-width: 960px) {
    width: 850px;
  }
  @media screen and (max-width: 800px) {
    width: 700px;
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-weight: 800;
  font-size: 30px;
  color: #4C77FF;
  margin-bottom: 33px;
  align-self: center;
  width: 1110px;
  @media screen and (max-width: 1100px) {
    width: 1000px;
    font-size: 26px;
    margin-bottom: 33px;
  }
  @media screen and (max-width: 960px) {
    width: 850px;
    font-size: 23px;
    margin-bottom: 27px;
  }
  @media screen and (max-width: 800px) {
    width: 700px;
    font-size: 20px;
    margin-bottom: 22px;
  }
`;

const Text = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  line-height: 23px;
  color: #212939;
  text-align: left;
  white-space: break-spaces;
  margin-bottom: 50px;
  margin-right: 28px;
  @media screen and (max-width: 1100px) {
    font-size: 13px;
    line-height: 18px;
  }
  @media screen and (max-width: 960px) {
    font-size: 11px;
    line-height: 15px;
  }
  @media screen and (max-width: 800px) {
    font-size: 9px;
    line-height: 12px;
  }
`;

const Text2 = styled(Text)`
  margin-right: 0;
`;

const CertificateText = styled(Text)`
  margin-bottom: 0;
`;

const Subtitle = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 24px;
  color: #4C77FF;
  margin-bottom: 52px;
  @media screen and (max-width: 1100px) {
    font-size: 20px;
    margin-bottom: 42px;
  }
  @media screen and (max-width: 960px) {
    font-size: 16px;
    margin-bottom: 32px;
  }
  @media screen and (max-width: 800px) {
    font-size: 12px;
    margin-bottom: 22px;
  }
`;

const ContainerForImage = styled.div`
  background: #E1F0FF;
  width: 255px;
  height: 350px;
  padding: 26px 22px;
  box-sizing: border-box;
  margin-right: 30px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 28px
`;

const RowContainer2 = styled(RowContainer)`
  display: flex;
  flex-direction: row;
  margin-bottom: 158px
`;

const RowContainer3 = styled(RowContainer)`
  display: flex;
  flex-direction: row;
  margin-bottom: 66px;
`;

const RowContainer4 = styled(RowContainer)`
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
`;

const RowContainer5 = styled(RowContainer)`
  display: flex;
  flex-direction: row;
  margin-bottom: 215px;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Mailto = styled.a`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #4C77FF;
  text-decoration: none;
  @media screen and (max-width: 1100px) {
    font-size: 13px;
    line-height: 18px;
  }
  @media screen and (max-width: 960px) {
    font-size: 11px;
    line-height: 15px;
  }
  @media screen and (max-width: 800px) {
    font-size: 9px;
    line-height: 12px;
  }
`;

const ImageAbout = styled.img`
  width: 490px;
  @media screen and (max-width: 1100px) {
    width: 400px;
  }
  @media screen and (max-width: 960px) {
    width: 320px;
  }
  @media screen and (max-width: 800px) {
    width: 270px;
  }
`;

const OurContacts = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 13px;
  color: #103072;
`;

const OurContactsText = styled(Text)`
  margin-bottom: 33px;
`;

const ReturnImages = () => {
    return (
        <>
            <Image src={Decoration} alt="Decoration"/>
            <Image2 src={Decoration} alt="Decoration"/>
            <Image3 src={Decoration} alt="Decoration"/>
            <Image4 src={Decoration} alt="Decoration"/>
        </>
    )
}

const Image = styled.img`
  position: absolute;
  left: -450px;
  top: 70px;
`;

const Image2 = styled.img`
  position: absolute;
  left: 1230px;
  top: 750px;
  z-index: -1;
  transform: matrix(-1, 0, 0, 1, 0, 0)
`;

const Image3 = styled.img`
  position: absolute;
  left: -450px;
  top: 1380px;
`;

const Image4 = styled.img`
  position: absolute;
  left: 1230px;
  top: 1750px;
  z-index: -1;
  transform: matrix(-1, 0, 0, 1, 0, 0)
`;

const Comment = styled.img`
  width: 211px;
`;

const OwnUl = styled.ul`
  padding-inline-start: 25px;
  max-width: 855px;
`;

const LiText = styled.li`
  font-family: Montserrat;
  font-size: 16px;
  color: #212939;
  line-height: 22px;
  @media screen and (max-width: 1100px) {
    font-size: 14px;
    line-height: 18px;
  }
  @media screen and (max-width: 960px) {
    font-size: 11px;
    line-height: 15px;
  }
  @media screen and (max-width: 800px) {
    font-size: 9px;
    line-height: 12px;
  }
`;


const About = () => {
    const data = useStaticQuery(query);

    return (
        <div style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
            <Seo title={data.allStrapiAboutSeos.nodes[0].seo_title}
                 description={data.allStrapiAboutSeos.nodes[0].seo_description}
                 keywords={data.allStrapiAboutSeos.nodes[0].seo_keywords.split(',')}
            />
            <MainContainer>
                <div style={{alignSelf: "center"}}>
                    <Header/>
                </div>
                <Title>
                    О компании
                </Title>
                <Container>
                    <ReturnImages/>
                    <RowContainer>
                        <Text>
                            {data.allStrapiAboutTexts.edges[0].node.text}
                        </Text>
                        <ImageAbout src={data.allStrapiAboutImages.edges[0].node.image.localFile.url} alt="PeopleImg"/>
                    </RowContainer>
                    <Text2>
                        {data.allStrapiAboutTexts.edges[1].node.text}
                    </Text2>
                    <OurContacts>
                        Основные направления деятельности:
                    </OurContacts>
                    <OwnUl style={{marginBottom: "50px"}}>
                        <LiText>
                            Комплексная разработка и реализация проектов при новом строительстве, реконструкции,
                            техническом перевооружении предприятий химической, нефтехимической и <br/>
                            нефтеперерабатывающей промышленности,
                            реализации и хранении нефтепродуктов.
                        </LiText>
                        <LiText>
                            Обустройство нефтяных и газовых месторождений
                        </LiText>
                        <LiText>
                            Разработка технико-экономического обоснования на выполнения проектных работ.
                        </LiText>
                    </OwnUl>
                    <OurContacts>
                        Наши ценности:
                    </OurContacts>
                    <Text style={{marginBottom: "0", maxWidth: '855px'}}>
                        ООО «Пластполимер-Сибирь» стремится быть значимой организацией на рынке проектно-изыскательских
                        и исследовательских работ в области химической, нефтехимической и нефтеперерабатывающей
                        промышленности, а также:
                    </Text>
                    <OwnUl style={{marginBottom: "50px", marginBlockStart: "0"}}>
                        <LiText>
                            придерживается концепции социально-ответственного бизнеса, не поддерживает
                            экологически-вредные и социально-опасные проекты;
                        </LiText>
                        <LiText>
                            является ответственным партнером государства, отчисляя установленные законодательством
                            налоги в бюджет, обеспечивая дополнительные
                            рабочие места, а также полный социальный пакет для каждого сотрудника компании;
                        </LiText>
                        <LiText>
                            предлагает максимальные усилия для развития промышленности России, предоставляя
                            профессиональную помощь партнерам в сфере
                            проектно-изыскательских и исследовательских работ в области технологии производства;
                        </LiText>
                        <LiText>
                            ориентирована на непрерывное развитие и совершенствование в химической и
                            нефтеперерабатывающей отрасли, вовлекая в этот процесс
                            сотрудников и партнеров.
                        </LiText>
                    </OwnUl>
                    <OurContacts>
                        Наши контакты:
                    </OurContacts>
                    <OurContactsText>
                        Общество с ограниченной ответственностью "Пластполимер-Сибирь" (ООО «Пластполимер-Сибирь)<br/><br/>
                        Юридический адрес: 634041, Томская область, г. Томск, проспект Кирова, д. 23, помещение 40<br/>
                        Почтовый адрес: 634041, Томская область, г. Томск, проспект Кирова, д. 23, помещение 40<br/>
                        Электронная почта: <Mailto href="mailto:pps@pp-proekt.ru">pps@pp-proekt.ru</Mailto>
                    </OurContactsText>
                    <YandexMap/>
                    <Subtitle>
                        Сертификаты и свидетельства
                    </Subtitle>
                    <RowContainer2>
                        <ContainerForImage style={{marginRight: "124px"}}>
                            <a rel="noreferrer" target="_blank" href={Certificates}>
                                <img src={data.allStrapiAboutImages.edges[5].node.image.localFile.url}
                                     alt="se"/>
                            </a>
                        </ContainerForImage>
                        <ColumnContainer>
                            <RowContainer3>
                                <a href="http://tposro.ru/">
                                    <img style={{marginRight: "103px"}}
                                         src={data.allStrapiAboutImages.edges[1].node.image.localFile.url}
                                         alt="CRO"/>
                                </a>
                                <CertificateText>
                                    Член Саморегулируемой организации -<br/>Ассоциации Томское Проектное
                                    Объединение<br/>по
                                    повышению качества проектной продукции
                                </CertificateText>
                            </RowContainer3>
                            <RowContainer4>
                                <a href="http://www.li-sro.ru/">
                                    <img style={{marginRight: "31px", width: "159px"}}
                                         src={data.allStrapiAboutImages.edges[2].node.image.localFile.url}
                                         alt="CRO"/>
                                </a>
                                <CertificateText>
                                    Член Ассоциации в области инженерных<br/>изысканий «Саморегулируемая
                                    организация<br/>«ЛИГА
                                    ИЗЫСКАТЕЛЕЙ»
                                </CertificateText>
                            </RowContainer4>
                        </ColumnContainer>
                    </RowContainer2>
                    <Subtitle>
                        Благодарственные письма
                    </Subtitle>
                    <RowContainer5>
                        <ContainerForImage>
                            <a rel="noreferrer" target="_blank"
                               href={data.allStrapiAboutImages.edges[3].node.image.localFile.url}>
                                <Comment src={data.allStrapiAboutImages.edges[3].node.image.localFile.url}
                                         alt="letter1"/>
                            </a>
                        </ContainerForImage>
                        <ContainerForImage>
                            <a rel="noreferrer" target="_blank"
                               href={data.allStrapiAboutImages.edges[4].node.image.localFile.url}>
                                <Comment src={data.allStrapiAboutImages.edges[4].node.image.localFile.url}
                                         alt="letter2"/>
                            </a>
                        </ContainerForImage>
                    </RowContainer5>
                </Container>
            </MainContainer>
            <Footer/>
        </div>
    )
}

const query = graphql`
query{
  allStrapiAboutImages {
    edges {
      node {
        image {
          localFile {
            url
          }
        }
      }
    }
  }
  allStrapiAboutTexts {
    edges {
      node {
        text
      }
    }
  }
    allStrapiAboutSeos {
    nodes {
      seo_title
      seo_decription
      seo_keywords
    }
  }
}
`;

export default About;